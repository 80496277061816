<template>
  <div>
    <h1>THIS USER IS DELETED</h1>
    <h3>302</h3>

    <router-link to="/" tag="button">BACK TO FEED</router-link>
  </div>
</template>

<script>
export default {
  name: "DeletedUserRoute",
};
</script>

<style lang="scss" scoped>
div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 100%;
  width: 100%;
  padding-top: 15%;

  background-color: var(--background);

  h1 {
    color: var(--text);
  }
  h3 {
    color: var(--orange);
  }
  button {
    @include button;
  }
}
</style>
